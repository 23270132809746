:root {
  --first-color: rgb(246 133 22);
  /* --first-color: #ef3d50; */
  --first-color-dark: #283a4c;
  --first-color-dark-light: rgb(53, 73, 93);
  --first-color-lighter: rgb(5, 198, 5, 0.1);
  --body-bg-color: #f4f7fc;
  --gray-light: #dddddd;
  --body-color-lighter: #ffffff;
  --color-white: #ffffff;
  --text-color: #000000;
  --border: #dfdfdf;
  --color-danger: rgb(255, 0, 0);
  --color-danger-lighter: rgba(240, 221, 221, 0.4);

  --xlarge-title-size: 60px;
  --large-title-size: 33px;
  --small-title-size: 25px;
  --x-sm-title-size: 16px;

  --box-shadow: rgba(128, 128, 128, 0.1) 0px 4px 6px -1px,
    rgba(138, 138, 138, 0.06) 0px 2px 4px -1px;
}

body {
  background-color: var(--body-bg-color);
  color: var(--text-color);
  /* overflow-y: hidden; */
  font-size: 14px;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

a:hover {
  color: var(--text-color);
}

li {
  list-style: none;
}

h1 {
  font-size: var(--xlarge-title-size);
  margin-bottom: 0;
  color: var(--text-color);
}

h2 {
  font-size: var(--large-title-size);
  margin-bottom: 0;
  font-weight: 900;
  color: var(--text-color);
}

h3 {
  font-size: var(--x-sm-title-size);
  margin-bottom: 0;
  font-weight: 700;
  color: var(--text-color);
}

.title-large {
  font-size: 22px;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.app-containers {
  height: 100%;
  overflow-y: auto;
  /* padding-bottom: 55px; */
}

.text-primary {
  color: var(--first-color) !important;
}

.text-disabled {
  opacity: 0.8 !important;
}

.text-p-x {
  font-size: 15px;
}

.p-horizontal {
  margin: 15px 21px;
}

.p-text-lg-page {
  font-size: 16px;
}

.card {
  background-color: var(--body-color-lighter);
  border: 0;
  /* box-shadow: 0px 10px 40px 40px rgba(165, 165, 165, 0.02); */
  box-shadow: var(--box-shadow);
}

.form-card {
  padding: 1.8rem;
  border-radius: 8px;
}

.card-header {
  background-color: var(--body-color-lighter) !important;
  border: 0;
  padding-top: 1rem;
}

.card-footer {
  /* background-color: var(--body-bg-color) !important; */
}

.auth-form-container {
  height: 100vh;
  overflow-y: auto;
  padding: 3rem 0;
}

.card-footer {
  background-color: var(--body-color-lighter);
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3rem;
}

/* scroll bar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: var(--border);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(189, 189, 189);
  border-radius: 10px;
}

.main-layout-dashboard {
  display: flex;
}

.dash-container-wrapper {
  flex: 1;
  height: 100vh;
  overflow-y: hidden;
}

.dash-content {
  padding: 0 1rem;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 100px;
}

hr {
  margin: 0.5rem 0;
  color: inherit;
  background-color: #c2c2c2;
  border: 0;
  opacity: 0.25;
}

.border-top {
  border-top: 1px solid var(--border);
}

.dropdown-menu {
  border: 0 !important;
  box-shadow: var(--box-shadow);
  padding: 0;
  border: 1px solid var(--border) !important;
}

.dropdown-menu li {
  min-height: 2rem;
  display: flex;
  align-items: center;
}

.cdropdown-container {
  min-width: 300px;
  max-height: 70vh;
  overflow-y: auto;
}

.cdropdown-container a {
  color: var(--first-color);
}

.cdropdown-container .separator-title {
  font-size: 16px;
  margin: 1rem 0;
  opacity: 0.5;
}

.cdropdown-container .dropdown-item {
  min-height: 2rem;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  /* max-width: 100px; */
}

dropdown-item {
  font-size: 12px;
  font-weight: 700;
}

.landing-page {
  font-family: "Montserrat", sans-serif;
}
