.splash-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.splash-logo{
    width: 150px;
    height: 150px;
}

.splash-container img{
    aspect-ratio: 3/2;
    width: 100%;
    object-fit: contain;
}