.table-filters {
  margin-bottom: 2rem;
}

.table-card-header {
  background-color: var(--gray-light);
}

.sort-btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.3;
}

.sort-btn i {
  font-size: 19px;
  padding: 0;
  margin: 0;
}

.sort-btn:hover,
.sort-btn:active {
  opacity: 1;
  color: var(--color-secondary);
}

td {
  font-size: 13px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--border);
}

th {
}

td {
  vertical-align: middle;
}

.search-container{
  position: relative;
}

.search-container button{
  margin-left:15px;
  position: absolute;
  right:0;
  top:0;
  bottom:0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.checkbox-container-table{
  display: flex;
  align-items: center;
}

.checkbox-container-table input{
  margin-right: 8px;
}

.custom-dropdown {
  position: inherit !important;
}

.action-btn {
  width: 100%;
  height: 100%;
  /* padding: 0.35rem 1.5rem; */
  border: none;
  outline: none;
  text-shadow: none;
  font-size: 15px;
  opacity: 1;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  /* min-height: 40px; */
}
