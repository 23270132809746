
.filter-body {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    height: 100dvh;
    min-height: 580px;
    z-index: 99999999;
    border-radius: 0.4rem 0  0 0.4rem;
    background: white;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* align-items: ; */
    /* justify-content: space-between; */
}

.filter-top button i,
.filter-top h1{
    font-size: 18px;
    font-weight: 700;
}
.filter-top button i{
    font-size: 22px;

}
.filter-top{
    height: 60px;
    border-bottom: 1px solid var(--gray-light);
    display: flex;
    align-items: center;
    width: 100%;

}

.filter-top-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 0 12px;
}


.filter-center {
    height: 100%;
    overflow-y: auto;
    padding: 0 12px;

}

.filter-bottom {
    border-top: 1px solid var(--gray-light);
    height: 60px;
    margin-bottom: 0.5rem;
    

}

.filter-bottom-items {
    display: flex;
    justify-content: end;
    gap: 1.3rem;
    align-items: center;
    padding: 12px;
}