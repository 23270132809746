.btn {
  padding: 0.35rem 1.5rem;
  border-radius: 5px;
  text-shadow: none;
  outline: none;
  font-size: 14px;
  opacity: 1;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  min-height: 40px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:disabled,
.btn-primary:focus {
  border-color: var(--first-color);
  background-color: var(--first-color) !important;
  font-weight: 600;
  box-shadow: none;
  outline: none;
}

.btn-primary:disabled {
  opacity: 0.3;
}

.btn-bordered {
  border: 2px solid var(--first-color);
  color: var(--first-color);
}

/* .btn-lg {

} */

.btn-home {
  padding: 0.8rem 4rem !important;
}

.btn-default {
  background-color: var(--body-bg-color);
}

.bordered {
  border: 1px solid var(--border);
}

.btn .selected_item {
  /* font-weight: 700; */
  color: var(--text-color);
  padding: 0.5rem;
  /* margin: 0 0.5rem; */
  border-radius: 1rem;
}

.dropdown-menu {
  white-space: nowrap;
}

.btn i {
  font-size: 18px;
  padding-right: 0.2rem;
}

.btn-transparent {
  background-color: transparent !important;
}

.btn-transparent i {
  opacity: 0.8;
}

.btn-transparent:focus {
  border: 0;
  outline: none;
  box-shadow: none;
}

.btn-transparent.color-white {
  color: var(--color-white);
}

.btn-sm {
  font-size: 13px !important;
  padding: 0.3rem 0.8rem !important;
  height: 33px;
}

.btn-gray {
  background-color: var(--gray-light);
}

.btn-disabled {
  opacity: 0.2 !important;
}

.radius20 {
  border-radius: 20px;
}

.search-dropdown {
  box-shadow: var(--box-shadow);
  border: 1px solid var(--gray-light);
}

.search-dropdown ul {
  padding: 0;
  margin: 0;
}

.search-dropdown ul li {
  list-style: none;
  min-height: 50px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-bottom: 1px solid var(--gray-light);
  padding: 0 1rem;
}

.search-dropdown ul li:hover,
.search-dropdown ul li.active {
  cursor: pointer;
}

.search-dropdown ul li span {
  flex: 1;
  font-size: 14px;
  max-width: 300px;
}

.custom-checkbox {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--border);
  margin-right: 1rem;
  border-radius: 4px;
}

.custom-checkbox.active {
  background-color: var(--first-color);
}

.custom-checkbox.active i {
  color: white;
}

.search-drop-input {
  padding: 1rem 1rem 0 1rem;
  position: relative;
}

.clear-drop-search {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.clear-drop-search a:hover {
  text-decoration: underline;
}

.search-dropdown-text-selected {
  max-width: 100px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer_options {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.footer_options span.count {
  flex: 1;
  font-weight: 600;
  color: var(--first-color);
  margin-right: 1rem;
  font-size: 14px;
}

.footer_options .buttons {
  display: flex;
  align-items: center;
}

.rem_selected_v {
  position: absolute;
  right: 0;
}

.dropdown-menu {
  /* white-space: nowrap; */
  /* max-width: 400px; */
}

.search-dropdown .content {
  max-height: 300px;
  overflow-y: auto;
  white-space: nowrap;
  overflow-x: hidden;
}
