.center-both-form{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.dil-logo-container img{
  aspect-ratio: 3/2;
  width: 60%;
  object-fit: contain;
  margin-bottom: 2rem;
}