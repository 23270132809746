.required {
  color: var(--color-danger);
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  background-color: var(--body-bg-color);
  border-color: var(--border);
  font-size: 13px;
  padding: 0.55rem 0.75rem;
  /* padding: 0.8rem 0.75rem; */
}

.form-control-lg {
  font-size: 16px;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid var(--first-color) !important;
}

.form-control::placeholder {
  font-size: 14px;
}

.form-control-error {
  border-color: var(--color-danger) !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 35px;
}

.checkbox-container .icon-container {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--border);
  border-radius: 3px;
  margin-right: 0.5rem;
}

.checkbox-container .icon-container.checked {
  background-color: var(--first-color);
}

.checkbox-container .icon-container.checked i {
  color: var(--body-color-lighter);
}

label {
  margin-bottom: 0.3rem;
}

textarea {
  min-height: 70px !important;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-container .icon-input-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  cursor: pointer;
}

.input-container .icon-input-container i {
  font-size: 18px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--first-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--first-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
