
.stepper {
    height: 100%;
    display: flex;
    width: fit-content;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
}

.stepper span {
    margin: 0.8rem 0.5rem;
}

.stepper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
    margin-bottom: 1.5rem;
    
}
.stepper-items {
    display: flex;
    width: fit-content;
    overflow-y: hidden;
    border-radius: 5px;
}

.stepper-close {
    display: flex;
    justify-content: center;
    width: fit-content;
}

.stepper-next{
    margin-top: 1.5rem;
}